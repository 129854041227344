import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { VersionNumber } from './VersionNumber';
import { Routes } from './Routes';

export const AppInner: React.FC = () => {
  const matched = useRouteMatch('/matched-packs') != null;
  return (
    <>
      <AppBar position="fixed" style={{ zIndex: 100 }}>
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#375954',
          }}
        >
          <RouterLink style={{ textDecoration: 'none' }} to="/">
            <Typography variant="h6" noWrap style={{ color: 'white' }}>
              TimberSmart Addons
            </Typography>
          </RouterLink>
          <Button
            component={RouterLink}
            to={matched ? '/' : '/matched-packs'}
            style={{ color: '#fff', backgroundColor: '#222' }}
            variant="outlined"
          >
            {matched ? 'Matched Packs' : 'Pending Packs'}
          </Button>
          <VersionNumber />
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: 64 }}>
        <Routes />
      </div>
    </>
  );
};
